var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"id":"pop-up"},on:{"submit":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_c('v-app-bar',{staticClass:"white pa-0",staticStyle:{"z-index":"3"},attrs:{"elevate-on-scroll":"","dense":"","scroll-target":"#scrolling-tran-edit","height":"60"}},[_c('v-toolbar-title',{staticClass:"pl-4",attrs:{"data-cy":"tran_detail_title"}},[_vm._v(" "+_vm._s(_vm.$t('transaction.details.title'))+" ")]),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.records && _vm.records.tran_type != 'loan'),expression:"records && records.tran_type != 'loan'"}],staticClass:"mr-3",attrs:{"outlined":"","depressed":"","href":'/v2/msoform/generate/' + (_vm.records ? _vm.records.id : 'none'),"target":"_blank","data-cy":"tran_detail_download_btn"}},[_vm._v(" Download ")]),_c('v-btn',{staticClass:"mr-3",attrs:{"outlined":"","depressed":"","color":"primary","data-cy":"tran_detail_save_btn"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.tran_detail.onSave()}}},[_vm._v(" "+_vm._s(_vm.$t('alert.details.buttons.save'))+" ")]),_c('PopupDelete',{attrs:{"data-cy":"tran_detail"},on:{"yes":function (done) {
        _vm.$store.dispatch('transaction/remove', _vm.records.id)
          .then(function () {
            this$1.$emit('onSave', null);
          })
          .catch(function (error) { _vm.console.warn(error) })
          .finally(done); // notify popup stop loading and close
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var on = ref.on;
      var loading = ref.loading;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"outlined":"","depressed":"","color":"error","loading":loading,"data-cy":"tran_detail_delete_btn"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" "+_vm._s(_vm.$t('person.details.buttons.delete'))+" ")],1)]}}])})],1),_c('v-card-text',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"calc(90vh - 80px)"},attrs:{"id":"scrolling-tran-edit"}},[_c('v-container',{attrs:{"fluid":""}},[_c('TransactionDetail',{ref:"tran_detail",attrs:{"records":_vm.records},on:{"update:records":function($event){_vm.records=$event},"onSave":_vm.onSave}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }